.root {
  overflow: hidden;
}

#headlessui-portal-root {
  @apply text-neutral-900 text-base;
}

* {
  box-sizing: border-box;
}

.nc-will-change-transform {
  will-change: transform;
}

.nc-SectionClientSay .glide__bullet--active {
  @apply bg-neutral-700;
}
.nc-GallerySlider .glide__bullet--active {
  @apply bg-sky-500 w-2 h-2;
}

// HERO
.nc-hero-field-padding {
  @apply py-3 px-4 md:py-4 md:px-7 xl:py-6 xl:px-8;
}

.nc-hero-field-padding--small {
  @apply py-3 px-4 md:py-3 md:px-7 xl:px-8;
}

.nc-hero-field-focused {
  @apply rounded-full;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
}

.ListingDetailPage {
  .nc-hero-field-focused {
    @apply rounded-3xl shadow-2xl;
  }
  &.nc-ListingExperiencesDetailPage {
    .ExperiencesDateSingleInput .nc-hero-field-focused {
      @apply rounded-r-none;
    }
    .listingExperiencesDetailPage__GuestsInput .nc-hero-field-focused {
      @apply rounded-l-none;
    }
  }
}

.nc-ListingStayDetailPage__guestsInput {
  .nc-hero-field-focused {
    @apply rounded-t-none;
  }
}
.nc-ListingStayDetailPage__stayDatesRangeInput {
  .nc-hero-field-focused {
    @apply rounded-b-none;
  }
}

.gm-ui-hover-effect {
  outline: none !important;
}
.gm-style-iw-t {
  bottom: 50px !important;
}
.gm-style-iw {
  max-height: 280px;
  max-width: 260px !important;
}

.custom-marker-label {
  padding: 2px 4px;
  border-radius: 4px;
  margin-top: 30px;
  text-align: center;  
  background-color:white;
  border:solid 1px;
}

select:focus {
  outline: none !important;
  border-color: transparent !important;
  box-shadow: none !important;
}

.nc-hero-field-focused--2 {
  @apply rounded-full;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
}

.nc-flex-1 {
  flex: 1 0 0%;
}

.nc-flex-1\.5 {
  flex: 1.5 0 0%;
}
.nc-flex-1\.5-auto {
  flex: 1.5 0 auto;
}

.nc-flex-2 {
  flex: 2 0 0%;
}

.nc-flex-2-auto {
  flex: 2 0 auto;
}

.nc-flex-2\.5 {
  flex: 2.5 0 0%;
}

@screen lg {
  .lg\:nc-flex-1\.5 {
    flex: 1.5 0 0%;
  }
  .lg\:nc-flex-1\.5-auto {
    flex: 1.5 0 auto;
  }

  .lg\:nc-flex-2 {
    flex: 2 0 0%;
  }

  .lg\:nc-flex-2-auto {
    flex: 2 0 auto;
  }

  .lg\:nc-flex-2\.5 {
    flex: 2.5 0 0%;
  }
}

.nc-icon-field {
  @apply w-5 h-5 lg:w-7 lg:h-7;
}
.nc-icon-field-2 {
  @apply w-5 h-5 lg:w-8 lg:h-8;
}
//
button.gm-control-active.gm-fullscreen-control {
  @apply rounded-xl #{!important};
}

//
.nc-FiveStartIconForRate {
  svg:hover ~ svg {
    @apply text-neutral-300;
  }
}

// LISTING PAGE DETAIL
.listingSection__wrap {
  @apply md:w-3/5 w-full flex flex-col sm:rounded-2xl border-b sm:border-t sm:border-l sm:border-r border-neutral-200  space-y-6 sm:space-y-8 pb-10 px-0 sm:p-4 xl:p-8;
}

.listingSection__wrap__custom {
  @apply md:w-3/5 w-full  flex flex-col sm:rounded-2xl border-b sm:border-t sm:border-l sm:border-r border-neutral-200   sm:space-y-3 pb-8 px-0 sm:p-4 xl:p-8;
}

.listingSection__wrap_column {
  @apply w-full flex flex-col sm:rounded-2xl border-b sm:border-t sm:border-l sm:border-r border-neutral-200  space-y-4 sm:space-y-4 pb-10 px-0 sm:p-4 xl:p-8;
}

// LISTING PAGE DETAIL
.listingSectionSidebar__wrap {
  @apply w-full flex flex-col rounded-2xl border-b border-t border-l border-r border-neutral-200  space-y-6 xl:space-y-7 pb-10 p-2 sm:p-4 xl:px-8 xl:py-6;
}

//
input[type="time"]::-webkit-calendar-picker-indicator {
  @apply rounded-md;
}

[type="button"] {
  -webkit-appearance: none !important;
}

//
.rc-slider-track {
  @apply bg-primary-400;
}
.rc-slider-handle {
  @apply border-primary-300;
}

//
.modalPhotos-single-gallery {
  .glide__bullet--active {
    @apply block;
  }
}

.menuIsNew_lv1 {
  > a::before {
    content: "New!";
    position: absolute;
    top: -2px;
    right: 0;
    display: inline-block;
    padding: 0 5px;
    line-height: 15px;
    border-radius: 4px;
    background-color: #f35656;
    color: #fff;
    font-size: 10px;
    font-weight: normal;
  }
}
.menuIsNew {
  > a::after {
    content: "New!";
    display: inline-block;
    padding: 0 5px;
    line-height: 15px;
    border-radius: 4px;
    background-color: #f35656;
    color: #fff;
    font-size: 10px;
    margin-left: 6px;
  }
}

.FooterNav--hide {
  @apply translate-y-full;
}

@keyframes myblur {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
